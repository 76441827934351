import React, { useState, useEffect, cloneElement } from 'react';
import Dropzone from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { Button } from '@common/components/button';
import Icon from '@common/components/icon';
import { useAppSelector, useDidComponentMount } from '@common/hooks';
import Api from '@common/services/api';
import { combineClassNames } from '@common/utils/combineClassNames';
import Tabs from '@common/components/tabs';
import Modal from '@common/components/modal';
import { setFormFileAttachments } from '@common/components/form/utils';
import { UnsplashInput } from '../unsplash';

const ONE_MB_IN_BYTES = 1000 * 1000;

// @ts-expect-error
const handleSelect = async ({ regular, raw }, value, onChange, organisationId, processFile) => {
  if (processFile) {
    const { data } = await Api.post(`/v2/organisations/${organisationId}/files/url`, {
      url: regular,
      is_document: false,
      file_type: 'image',
    });
    onChange(data, (Array.isArray(value) ? value.length : undefined));
  } else {
    const res = await fetch(regular || raw);
    const blob = await res.blob();
    const file = new File([blob], 'image');
    onChange(file);
  }
};

type FileInputValue = any;

export type FileInputOwnProps = {
  value?: FileInputValue;
  onChange?: (value: FileInputValue, index?: number) => void;
  children?: any;
  accept?: string;
  acceptedExtLabel?: string;
  className?: string;
  maxFileSize?: number;
  multiple?: number | boolean;
  processFile?: boolean;
  placeholder?: any;
  trigger?: any;
  disabled?: boolean;
  dragndrop?: boolean;
  unsplash?: boolean;
  modal?: boolean;
  reverseTabs?: boolean;
};

export const FileInput = ({
  value,
  onChange,
  children,
  accept,
  acceptedExtLabel,
  placeholder,
  trigger,
  dragndrop,
  disabled,
  maxFileSize = 30,
  multiple = false,
  processFile = false,
  unsplash,
  modal,
  reverseTabs,
  className = '',
  ...props
}: FileInputOwnProps) => {
  const { t } = useTranslation();
  const isMounted = useDidComponentMount();
  const organisationId = useAppSelector((state) => state.organisation.selected.id);

  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [modalWrapper, setModalWrapper] = useState<HTMLDivElement | void>();
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);

  useEffect(() => {
    // @ts-expect-error
    if (value?.progress) value.progress((newValue) => isMounted && setProgress(newValue));
  }, [value && value.progress]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeModal = (_: any) => setIsVisible(false);

  // Check if user can select more files
  const hasMaxFiles = multiple && multiple !== true && value && (value.length >= multiple);

  const handleEventPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const stopPropagation = target
      ? target.closest('.stopPropagation') !== null
      : false;
    if (!e.currentTarget.contains(target) || stopPropagation) {
      return e.stopPropagation();
    }
  };

  let inputElement = (() => {
    const triggerElement = !dragndrop
      ? trigger || children
      : (
        <div className="Form__FileInput__Trigger" onClick={handleEventPropagation}>
          {value && !isEmpty(value) && !Array.isArray(value) ? (
            <div className="Form__FileInput_Uploading">
              <div>
                <Icon type="arrow_circle_down__filled" />
                {(value && value.name) || value.file_name}
              </div>
              {value.progress && value.processing && (
              <small>
                {(progress || 0) < 100
                  ? <Trans i18nKey="common:form_input_file_uploading" values={{ percentage: Math.round(progress || 0) }} />
                  : <Trans i18nKey="common:form_input_file_processing" />}
              </small>
              )}
            </div>
          ) : (
            <div className="Form__FileInput__DefaultTrigger">
              <div className="Form__FileInput__DefaultHeader">
                <Icon type="arrow_circle_down__filled" />
                {placeholder || (
                  <b><Trans i18nKey="common:form_input_file_drag_or_click" components={[<u />]} /></b>
                )}
              </div>

              <small>{acceptedExtLabel}</small>
              <small>{`(${t('common:form_input_file_max_file_size', { maxFileSize })})`}</small>

              {unsplash && dragndrop && !modal && (
                <FileInput
                  value={value}
                  onChange={onChange}
                  accept={accept}
                  maxFileSize={maxFileSize}
                  multiple={multiple}
                  processFile={processFile}
                  unsplash
                  modal
                  {...props}
                >
                  <Button className="Form__FileInput__UnsplashModalButton stopPropagation">
                    {`${t('common:form_input_file_unsplash_library')}...`}
                  </Button>
                </FileInput>
              )}
            </div>
          )}
        </div>
      );

    const classNames = combineClassNames('Form__FileInput', className, {
      'Form__Dropzone': dragndrop,
      'Form__Dropzone--has-file': !!(value && !multiple),
      'Form__FileInput--disabled': disabled || hasMaxFiles,
    });

    if (disabled && hasMaxFiles) {
      return (
        <div className="Form__FileInput Form__FileInput--disabled">
          {triggerElement}
        </div>
      );
    }

    return (
      <>
        {
          rejectedFiles?.length > 0 ?
            (
              // as of now rejected files would contain only files that are too
              // big, but if additional rules are added to the dropzone we would
              // need to understand why the file got rejected
              rejectedFiles.map((file: File) => {
                return (
                  <div key={URL.createObjectURL(file)} className="fileWarning">
                    {
                      t('common:file_is_too_large', {
                        maxFileSize,
                        fileName: file.name
                      })
                    }
                  </div>
                );
              })
            ) :
            null
        }
        <Dropzone
          {...{ accept, className: classNames }}
          onClick={(e) => void e.preventDefault()}
          onDropRejected={setRejectedFiles}
          onDrop={(files) => {
            closeModal(
              setFormFileAttachments(
                files,
                value,
                organisationId,
                onChange,
                t,
                { maxFileSize, multiple, processFile },
              )
            );
          }}
          multiple={!!multiple}
          maxSize={maxFileSize * ONE_MB_IN_BYTES}
          disabled={disabled || hasMaxFiles}
        >
          {triggerElement}
        </Dropzone>
      </>
    );
  })();

  if (unsplash && dragndrop && modal) {
    inputElement = (
      <Tabs variant="full_width" reverse={reverseTabs}>
        <Tabs.Item title={t('common:form_input_file_image')}>
          <UnsplashInput
            onSelect={(item) => closeModal(handleSelect(item.urls, value, onChange, organisationId, processFile))}
            scrollContainer={modalWrapper}
            t={t}
          />
        </Tabs.Item>
        <Tabs.Item title={t('common:form_input_file_upload')}>
          {inputElement}
        </Tabs.Item>
      </Tabs>
    );
  }

  if (unsplash && !dragndrop) {
    inputElement = (
      <UnsplashInput
        onSelect={(item) => closeModal(handleSelect(item.urls, value, onChange, organisationId, processFile))}
        scrollContainer={modalWrapper}
        t={t}
      />
    );
  }

  const handleOnShow = () => {
    if (document) {
      setModalWrapper(document.getElementsByClassName('PicturePickerModal')[0] as HTMLDivElement);
    }
  };

  if (modal) {
    return (
      <Modal
        list
        show={isVisible}
        title={t('common:form_input_file_upload_photo_video')}
        onClose={() => setIsVisible(false)}
        onShow={handleOnShow}
        content={inputElement}
        className="PicturePickerModal"
      >
        {cloneElement(children, {
          onClick: () => setIsVisible(!isVisible),
          disabled: disabled || hasMaxFiles,
        })}
      </Modal>
    );
  }

  return inputElement;
};
