import React, { useState } from 'react';

import Api from '@common/services/api';
import { getApiHeaders, getApiUrl } from '@common/services/api/utils';
import { AlertService } from '@common/services/alert';
import { AsyncList } from '@common/components/list';
import SearchBar from '@common/components/search-bar';
import Placeholder from '@common/components/placeholder';

type UnsplashInputProps = {
  onSelect: (item: UnsplashItem) => void;
  scrollContainer: any;
  t: any;
};

type UnsplashItem = {
  id: string;
  alt_description?: string;
  width?: string;
  height?: string;
  urls: {
    thumb: string;
    small: string;
    regular: string;
    raw: string;
  };
};

export const UnsplashInput = ({ onSelect, scrollContainer, t }: UnsplashInputProps) => {
  const [search, setSearch] = useState<string | null>('');

  return (
    <>
      <SearchBar onSearch={setSearch} />
      <div className="Unsplash__Container">
        <AsyncList
          containerClassName="Unsplash__Content"
          scrollContainer={scrollContainer}
          renderRow={({ item }: { item: UnsplashItem }) => (
            <img
              onClick={() => onSelect(item)}
              className="Unsplash__Content__Item"
              alt={item.alt_description}
              src={item.urls.thumb}
              srcSet={item.urls.small}
              width={`${item.width}px`}
              height={`${item.height}px`}
            />
          )}
          data={{
            useCursor: undefined,
            filter: {
              query: search,
            },
            onFetch: async (offset, filter) => {
              const limitPerPage = 30;
              const page = +(offset!) / limitPerPage + 1;

              const query = Api.utils.toQuery({
                page,
                per_page: limitPerPage,
                query: filter!.query,
                orientation: 'landscape',
                collections: filter!.query ? undefined : true,
                id: filter!.query ? undefined : 'htcaqHWwbSw',
              });

              const headers = await getApiHeaders();
              const res = await fetch(`${getApiUrl('/unsplash')}?${query}`, { headers });
              if (!res.ok) {
                AlertService.error(t('organisation:track_modal_error'));
                return {
                  data: [] as UnsplashItem[],
                  meta: { pagination: {} },
                };
              }

              const json = await res.json();
              const { results, total } = json.results ? json : { results: json, total: json.length };
              return {
                data: results,
                meta: {
                  pagination: {
                    offset: +(offset!),
                    total_count: total,
                    limit: limitPerPage,
                  },
                },
              };
            },
          }}
          placeholder={(
            <Placeholder
              title={t('common:form_input_file_unsplash_placeholder', { context: search && 'search', search })}
            />
          )}
        />
      </div>
    </>
  );
};
