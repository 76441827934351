import React from 'react';
import { omit } from 'lodash';
import {
  Field, WrappedFieldProps, BaseFieldProps, FieldArray, WrappedFieldArrayProps,
} from 'redux-form';

import { FileInput, FileInputOwnProps } from './file-input';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const FileInputMultipleHookForm = ({ fields, append, update, ...props }: FileInputOwnProps & {
  fields: any[];
  append: (data: any) => void;
  update: (index: number, data: any) => void;
}) => {
  if (props.multiple) {
    return (
      <FileInput
        {...props}
        value={fields}
        onChange={(file, index) => {
          if (file instanceof File || file.is_remote_file) {
            append(file);
          } else if (index !== undefined) {
            update(index, file);
          }
        }}
      />
    );
  }
  return (
    <FileInput
      {...props}
      value={(fields.length && fields[0]) || []}
      onChange={(file: any) => update(0, file)}
    />
  );
};

const ReduxFormField = (props: WrappedFieldProps & FileInputOwnProps) => {
  return (
    <FileInput
      {...props.input}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

const ReduxFormFieldMultiple = (props: WrappedFieldArrayProps & FileInputOwnProps) => {
  return (
    <FileInput
      {...omit(props, ['meta', 'fields'])}
      value={props.fields.getAll()}
      onChange={(file: any) => {
        if (file instanceof File || file.is_remote_file) {
          props.fields.push(file);
        } else {
          let i = 0;
          while (props.fields.get(i)) {
            const f = props.fields.get(i);
            if (f instanceof File && f.name.split('.')[0] === file.file_name.split('.')[0]) {
              props.fields.splice(i, 1, file);
              break;
            }
            i++;
          }
        }
      }}
    />
  );
};

export default (props: Omit<BaseFieldProps<FileInputOwnProps>, 'component'> & FileInputOwnProps) => {
  if (props.multiple) {
    return (
      <FieldArray
        {...props}
        component={ReduxFormFieldMultiple}
      />
    );
  }
  return (
    <Field
      {...props}
      id={props.name}
      component={ReduxFormField}
    />
  );
};
