import * as R from 'ramda';
import { TFunction } from 'i18next';

import * as fileUtil from '@common/utils/file';
import { FileInProgress } from '@common/types/objects';

type Options = {
  maxFileSize?: number;
  processFile?: boolean;
  multiple?: number | boolean;
  preview?: boolean;
};

export const setFormFileAttachments = (
  actualFiles: File[],
  value: any,
  organisationId: string,
  onChange: ((value: any, index?: number) => void) | undefined,
  t: TFunction,
  { maxFileSize, processFile, multiple, preview }: Options,
) => {
  // Return if no files
  if (R.isEmpty(actualFiles)) return;

  // Force max amount of files
  const [files, excessFiles] = typeof multiple === 'number'
    ? R.splitAt(multiple - (Array.isArray(value) ? value.length : 0), actualFiles)
    : [actualFiles];

  // If user selected more files than are allowed
  if (excessFiles && excessFiles.length > 0) {
    // eslint-disable-next-line no-alert
    alert(t('common:form_input_file_alert_max_amount_of_files', { max: multiple }));
  }

  // Lets us keep track on files that are already in the form
  files.forEach((f, index) => {
    const file: File & FileInProgress = R.clone(f);
    const fileIndex = (Array.isArray(value) || typeof multiple === 'number')
      ? (Array.isArray(value) ? value.length : 0) + index
      : undefined;

    if (maxFileSize && !fileUtil.isValidFile(file, maxFileSize)) {
      // eslint-disable-next-line no-alert
      return alert(t('common:form_input_alert_file_max_file_size', { maxFileSize }));
    }

    file.file_name = file.name;
    file.file_type = file.type;

    if (processFile) {
      const payload = new FormData();
      payload.append('file', file);

      file.processing = processFile;

      let isCancelled = false;

      (async () => {
        if (preview) {
          file.preview = await fileUtil.getImageBase64(file);
        }

        const { onProgress, promise } = await fileUtil.upload(
          `/v2/organisations/${organisationId}/files`,
          payload,
        );

        file.progress = onProgress;
        file.promise = promise;
        file.cancelPromise = () => (isCancelled = true);

        if (onChange) onChange(file, fileIndex);

        const attachment = await promise;

        if (!isCancelled) {
          attachment.preview = file.preview;
          if (onChange) onChange(attachment, fileIndex);
        }
      })();
    } else if (onChange) {
      onChange(f);
    }
  });
};
