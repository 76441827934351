import React, { useState } from 'react';
import Icon, { IconTypes } from '../icon';
import Spinner from '../spinner';
import { combineClassNames } from '../../utils/combineClassNames';

type Props = {
  children?: React.ReactNode;
  icon?: string;
  title?: string;
  description?: string;
  action?: React.ReactNode;
  image?: string;
  size?: 'small' | 'default';
  onCreate?: () => Promise<void>;
  form?: any;
  formProps?: unknown;
};

const Placeholder = ({
  children, icon, image, title, description, action, size = 'default', onCreate, form: Form, formProps, ...props
}: Props) => {
  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = () => {
    if (onCreate) {
      setIsCreating(true);

      onCreate();
    }
  };

  const classNames = ['Placeholder'];
  if (size) classNames.push(`Placeholder--${size}`);

  const text = children || title;

  let content = (
    <>
      {icon && <Icon className="Placeholder__Icon" type={icon as IconTypes} />}
      {image && <img src={image} className="Placeholder__Image" alt={title} />}
      {typeof text === 'string' ? <h2>{text}</h2> : text}
      {description && <div className="Placeholder__Description">{description}</div>}
      {action}
    </>
  );

  if (Form) {
    content = (
      // @ts-ignore
      <Form {...formProps}>
        <div className="Placeholder__Form">{content}</div>
      </Form>
    );
  }

  if (onCreate) {
    content = (
      <div className="Placeholder__Form" onClick={handleCreate} role="button">
        {isCreating ? <Spinner size="large" centered /> : content}
      </div>
    );
  }

  return (
    <div
      className={combineClassNames('Placeholder', `Placeholder--${size}`)}
      {...props}
    >
      <div className="Placeholder__Inner">
        {content}
      </div>
    </div>
  );
};

export default Placeholder;
